@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

.container{
    margin-top: 2rem;
    min-height:80vh;
    display:flex;
    justify-content:center;
    align-items:center;
    background-color:#ffffff;  
}
.container .card{
    height:530px;
    width:800px;
    background-color:#fff;
    position:relative;
    box-shadow:0 15px 30px rgba(0,0,0,0.1);
    font-family: 'Poppins', sans-serif;
}
.container .card .form{
    width:100%;
    height:100%;
    display:flex;
}
.container .card .left-side{
    width:50%;
    background: #71447A;
  background: -webkit-linear-gradient(to right, #71447A, #71447ac2
  );
  background: linear-gradient(to right, #71447A
  , #71447ac2);
    height:100%;
    overflow:hidden;
    
}
.left-side img{
    box-sizing:border-box;
    object-fit:cover;
    height:100%;
    width:100%;
}

.left-side-text{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  color: white;
}

.left-side-text h1{
    font-weight: bolder;
}

.left-side-text p{
    font-weight: 400;
}

.left-side-text button{
  background-color: transparent;
  border-color: #ffffff;
  font-size: 15px;
  font-weight: 800;
  padding: 12px 45px;
  letter-spacing: 1px;
  border-radius: 20px;
  color: white;

}

.container .card .right-side{
    width:50%;
    background-color:#fff;
    height:100%;
    padding:20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.heading h3{
    letter-spacing:1px;
}
.heading p{
    margin-top:5px;
    font-size:12px;
    color:#898989;
}

.right-side hr{
    margin-top:20px;
}
.right-side p{
    
}

.input-text{
    position:relative;
    margin-top:20px;
    width:100%;
}

.input-mobtext{
    font-weight: 600;
    font-size: 15px;
}

input[type="number"]{
    height:45px;
    width:100%;
    border:none;
    border-radius:7px;
    background-color:#f5f5f5;
    outline:0;
    padding:0 10px;
    font-size:13px;
    padding-left:30px;
}

input[type="text"]{
    height:45px;
    width:100%;
    border:none;
    border-radius:7px;
    background-color:#f5f5f5;
    outline:0;
    padding:0 10px;
    font-size:13px;
    padding-left:30px;
}

input[type="password"]{
    height:45px;
    width:100%;
    border:none;
    border-radius:7px; 
    background-color:#f5f5f5;
    outline:0; 
    padding:0 10px;
    font-size:13px;
    padding-left:30px;
    padding-right:30px;
}

.input-text label{
    position:absolute;
    left:30px;
    top:15px;
    font-size:12px;
    pointer-events:none;
    transition:all 0.5s;
}
.input-text i{
    position: absolute;
    font-size:13px;
    cursor:pointer;
    margin-top: 10px;
}

.input-text .fa-user{
    left:8px;
}

.input-text .fa-mobile{
    left:8px;
    font-size: 25px;
}


.input-text .fa-lock{
    position:absolute;
    left:8px;
    font-size:14px;
}
.input-text .fa-eye-slash{
    position:absolute;
    right:10px;
    
}
.input-text .fa-eye{
    position:absolute;
    right:10px;
}

.input-text input:focus~label,.input-text input:valid~label{
    top:2px;
    font-size:10px;
    font-weight:600;
}

.rem_pass text{
    font-size:12px;
    color:blue;
    cursor:pointer;
}
.button{
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:20px;
    
}
.button button{
    height:40px;
    width:100%;
    background-color:#624068d8;
    border:none;
    border-radius:8px;
    color:#fff;
    cursor:pointer;
    font-size:15px;
    font-weight: 700;
    transition:all 0.5s;
}
.button button:hover{
    background-color:#4b3150;
}

.login-or{
    margin: 1rem 0rem;
}

.login-or text{
    font-size: 20px;
    font-weight: 900;
}

.register{
    margin-top:30px;
    display:flex;
    justify-content:center;
}
.register p{
    font-size:11px;
    font-weight:700;
}
.register text{
    color:blue;
    text-decoration:underline;
    cursor:pointer;
}

.warning{
    border:1px solid rgb(250, 5, 5) !important;
}
.green{
    background-color:#5e2068e7 !important;
}
.text-warning{
    border:1px solid red !important;
}

.signin-error-message{
    color: rgb(255, 0, 0);
    background-color: #f09880;
    font-weight: 500;
    padding-left: 1rem;
    border-radius: 0.1rem;
}

.signin-error{
    color: red;
    font-size: 14px;
    font-weight: 600;
    /* font-family: fantasy; */
}

@media (max-width:750px) {
    .container .card {
        max-width: 350px;
        height:auto;
    }
    .container .card .right-side {
        width:100%;
       
    }
    .container .card .left-side {
        
        display: none;
    }
}