.aobutus-main-wrapper {
  display: flex;
  width: 100%;
  padding: 50px;
  background-color: white;
}
.left-section-logo-about-us {
  width: 50%;
  display: flex;

  align-content: center;
}
.left-section-logo-about-us img {
    max-width: 611px;
    width: 100%;
    max-height: 400px;
}
.main-contaner-about-us {
  width: 50%;
}

.main-contaner-about-us p {
  font-size: 16px;
}
.main-contaner-about-us h4 {
  color: #624068;
}
@media screen and (max-width: 1300px) {
  .main-contaner-about-us {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }
  .aobutus-main-wrapper {
    display: block;
    width: 100%;
    padding: 20px;
    background-color: white;
  }
  .left-section-logo-about-us {
    width: 100%;
    justify-content: center;
    /* display: flex;
    justify-content: center;
    align-content: center; */
  }
  .left-section-logo-about-us img {
    width: 100%;
  }
  .main-contaner-about-us p {
    font-size: 15px;
  }
  ..main-contaner-about-us h4{
      font-size: 19px;
  }
}

@media screen and (max-width: 650px) {
  .main-contaner-about-us {
    padding-left: 5px;
    padding-right: 5px;
  }
  .main-contaner-about-us p {
    font-size: 12px;
  }
  .main-contaner-about-us h4{
      font-size: 17px;
  }
}
