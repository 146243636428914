.orderdetails-container{
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.orderdetails-box{
    width: 50rem;
    min-height: 30rem;
    background-color:#ffffff;
    margin-left: 3%;
}

.orderdetails-title{
    display: flex;
    justify-content: center;
    font-weight: 800;
    font-family: monospace;
    padding: 1rem 0rem;
    border-bottom: 2px solid #f0f0f0;
}

.orderdetails-address{
    width: 100%;
    min-height: 7rem;
    border: 4px double #cac6c6;
    /* font-family: fantasy; */

}

.orderdetails-desc{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.1rem 0.4rem;
}
.orderdetails-desc-address{
    display: flex;
    flex-direction:column;
}

.orderdetails-desc-place{
    display: flex;
}

.orderdetails-total-price{
    display: flex;
    flex-direction: column;
}

.orderdetails-total-price_title{
    font-weight: 800;
}

.orderdetails-product-mobileview{
    display: none;
}

.orderdetails-one_order{
    width: 100%;
    height: 7rem;
    border: 4px solid #cac6c6;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.orderdetails-one_order img{
    width: 15%;
    height: 100%;

}

.orderdetails-product_name{
    /* font-family: fantasy; */
    font-size: 17px;
}

.orderdetails-product_list{
    display: flex;
    flex-direction: column;
}

.orderdetails-product_price{
margin: 0rem 0.4rem;
}

.orderdetails-product_price{

}

.orderdetails-product_update{
    display: flex;
    flex-direction: column;
}

.orderdetails-product_status{
    font-weight: 700;
    /* font-family: fantasy; */
}

.orderdetails-cancel{
    display: flex;
    justify-content: end;
    margin: 1rem;
}

.orderdetails-cancel button{
    border: none;
    background-color: rgba(226, 6, 6, 0.877);
    color: white;
    padding: 0.5rem 1rem;
    font-size: 18px;
    font-weight: 700;
    border-radius: 5px;
}

.orderdetails-cancelReason{
    display: flex;
    margin: 1rem;
    color: red;
}

.orderdetails-cancelReason text{
    font-size: 17px;
    font-weight: 700;
}

/* Add this to your existing CSS file */

.orderdetails-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.orderdetails-cancel-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    z-index: 1000;
    width: 40%;
  }
  
  .orderdetails-cancel-textarea {
    width: 100%;
    height: 100px;
    resize: none;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    padding: 5px;
  }
  
  .orderdetails-cancel-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .orderdetails-cancel-buttons button {
    border: none;
    background-color: rgba(0, 0, 0, 0.1);
    color: #333;
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .orderdetails-cancel-close {
    cursor: pointer;
    font-size: 20px;
    color: #aaa;
  }
  

@media screen and (max-width:1200px) {
    .orderdetails-box{
        width: 40rem;
        margin-left: 0.5rem;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width:850px) {
    .orderdetails-container{
        margin-top: 1rem;
    }
    .orderdetails-box{
        width: 100%;
        margin-left: 0rem;
        margin-bottom: 1rem;
    }
    .orderdetails-cancel-box {
        padding: 20px;
        z-index: 1000;
        width: 70%;
      }
}

@media screen and (max-width:700px) {
    .orderdetails-one_order img{
        width: 20%;
        height: 100%;
        margin: 0.1rem;
    }

    .orderdetails-total-price{
        display: none;
    }

    .orderdetails-product_update{
        display: none;
    }

    .orderdetails-product-mobileview{
        display: block;
    }
    .orderdetails-total-price-mobileview{
        display: flex;
        flex-direction: column;
    }
    .orderdetails-product_update-mobileview{
        display: flex;
        flex-direction: column;
    }

    .orderdetails-desc{
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 0.1rem 0.4rem;
    }

}