.Categories-Container {
  display: flex;
  /* padding: 20px; */
  padding-left: 12px;
  /* flex-wrap: wrap; */
  overflow-x: scroll;
  justify-content: space-evenly;
  width: 100%;
  gap: 10px;
  padding-top: 50px;

}
.big-sale-poster{
  padding-bottom: 50px;
}
.big-sale-poster img {
  height: 330px;
  /* width: 100%; */
  /* min-width: 400px; */
  /* padding-right: 80px; */
  width: 850px;
  max-width: 100%;
  cursor: pointer;
}
.Categories-Container::-webkit-scrollbar {
  display: none;
}
.categories-big-sale-main-wrapper {
  display: flex;
  /* justify-content: space-between; */
}
.ads-banner-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}


@media screen and (max-width: 1000px) {
  .categories-big-sale-main-wrapper {
    display: block;
  }
  
  .big-sale-poster img {
    height: auto;
    width: 100%;
    padding: 10px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 10px;
  }
  .Categories-Container{
      width: 100%;
      gap: 10px;
      justify-content: flex-start;
  }
  
}
/* 
@media screen and (max-width: 900px) {
    .Categories-Container{
        justify-content: center;
    }
  } */

