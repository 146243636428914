/* ---Sidebar css */
.text-primary{
    color: #5a8dee!important;
  }
  .btn-primary {
      border-color: #5a8dee !important;
      background-color: #5a8dee !important;
      color: #fff;
  }
  .shadow-md {
      box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
  }

  .nav-container{
    display: flex;
    
    justify-content: center;
  }
  .navbar{
    width: 100%;
    border-radius: 5px;
  }
  .sidebar{
      /* width: 280px; */
      width: 25%;
      min-height: 100vh;
      box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
      background-color: #fff;
      position: fixed;
      top: 0;
      left: -100%;
      z-index: 1;
      transition: 0.5s;
  }
  .sidebar.active{
      left: 0;
  }
  .sd-header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
  }
  .sidebar-overlay{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.4);
      transition: 0.5s;
      opacity: 0;
      visibility: hidden;
  }
  .sidebar-overlay.active{
      opacity: 1;
      visibility: visible;
  }
  .sd-body{
      padding: 15px;
      max-height: calc(100vh - 67px);
      overflow-x: hidden;
  }
  .sd-body ul{
      display: inline-block;
      width: 100%;
      margin-bottom: 0;
      padding: 0;
  }
  .sd-body ul li{
      list-style: none;
      margin-bottom: 8px;
  }
  .sd-body ul li .sd-link{
      display: inline-block;
      width: 100%;
      padding: 10px 16px;
      color: #475f7b;
      background-color: #e5e8ec;
      border-radius: 4px;
      cursor: pointer;
      text-decoration: none;
  }
@media screen and (max-width: 500px) {
  .sidebar{
      /* width: 280px; */
      width: 80%;
  }
}

