.reviews-Maincontainer {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 0% 5%;
}
.input {
  height: 40px;
  width: 23%;
  margin-bottom: 2%;
  outline: none;
  border: none;
  background-color: beige;
  margin-right: 2%;
}
.title {
  font-size: 20px;
  font-weight: bold;
  color: #71447a;
}
.book-review {
  width: 80%;
  display: flex;
  flex-direction: row;
  padding: 2%;
  gap: 30px;
}
.book-image-div {
  /* width: 35%; */
  /* height: 205px; */
  width: 135px;
  display: flex;
}
.book-image-div img {
  max-width: 100px;
  width: 100px;
  max-height: 100px;
}
.book-review-details {
  width: 75%;
  display: flex;
  flex-direction: column;
  margin-left: 2%;
}
.book-rating {
  font-size: 200%;
  font-weight: bold;
  color: var(--brown);
}
.star-rating {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 5%;
}
.small-text {
  margin: 4px;
  font-size: 95%;
  color: var(--brown);
}
.btn-review {
  color: white;
  padding: 2%;
  text-transform: uppercase;
  border-radius: 50px;
  display: block;
  /* width: 30%; */
  width: 170px;
  cursor: pointer;
  border: none;
  background: #624068;
  font-size: 13px;
}
.divider-solid {
  width: 90%;
  border-top: 1px solid #212529;
}
.divider-solid-fill {
  width: 100%;
  border-top: 1px solid #212529;
}
.amazing-reviews {
  width: 85%;
  margin-bottom: 2%;
}
.filter-div {
  width: 85%;
  display: flex;
  flex-direction: row;
  font-family: "Quicksand-SemiBold";
  color: #212529;
}
.review-count {
  width: 50%;
  align-items: flex-start;
  text-align: start;
  color: #212529;
}
.review-filter {
  width: 50%;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: end;
  color: var(--brown);
}
.review-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 2%;
}
.review-container-details {
  display: flex;
  flex-direction: column;
  width: 90%;
}
.review-container-rating-details {
  display: flex;
  flex-direction: row;
}
.review-name {
  /* margin-left: 1%; */
  font-weight: bold;
  color: var(--brown);
}
.reviewer-image {
  display: flex;
  flex-direction: column;
  /* width: max-content; */
  margin-right: 12px;
  width: 50px;
  height: 120px;
}
.reviewer-profile {
  width: 80%;
  height: 50%;
  background-color: black;
  color: white;
  text-align: center;
  font-size: 200%;
  margin: 5px;
}
.review-comment {
  display: flex;
  flex-direction: row;
  color: #d2d1d1;
  margin-top: 1%;
}
.review-icon {
  cursor: pointer;
}
.review-description {
  color: var(--brown);
  font-family: "Quicksand-SemiBold";
}
.review-feedback {
  color: #525252;
  margin-left: 5px;
  margin-right: 5px;
}
.normal-text {
  margin-left: 1%;
  margin-right: 1%;
  color: grey;
}
.modal-header {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  height: 60px;
  font-size: 180%;
  background-color: #624068;
  color: white;
  font-size: 14px;
}
.modal-contents {
  padding: 3% 5%;
}
.modal-input {
  margin-top: 2%;
  width: 100%;
  height: 40px;
  margin-bottom: 3%;
  border: 1px solid grey;
}
.modal-textarea {
  width: 100%;
  height: 80px;
  margin-bottom: 3%;
  border: 1px solid grey;
}
.modal-icon {
  width: 20px;
  height: 20px;
  margin-left: 5%;
  margin-right: 5px;
}
.modal-img {
  width: 100%;
  max-width: 100px;
  height: 100px;
}
.modal-title-container {
  display: flex;
  flex-direction: row;
}
.modal-img-container {
  display: flex;
  flex-direction: column;
}
.modal-des-container {
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 1% 3%;
}
.modal-title {
  font-size: 120%;
  color: var(--letter-black-dark);
  font-weight: bold;
}
.modal-sub-title {
  font-size: 90%;
  color: var(--letter-black-dark);
}
.label {
  vertical-align: top;
}
.modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
}
.modal-close-post {
  background-color: #624068;
  border: none;
  width: fit-content;
  color: white;
  padding: 1% 2%;
  border-radius: 50px;
  font-weight: bold;
}
.modal-close-btn {
  margin-left: 2%;
  background: #59514e;
  border: none;
  width: fit-content;
  color: white;
  padding: 1% 2%;
  border-radius: 50px;
  font-weight: bold;
}



@media (max-width: 1350px) {
  .modal-des-container {
    width: 90%;
  }
}
@media all and (max-width: 836.98px) {
  
  .book-rating {
    font-size: 150%;

    font-weight: bold;
    color: var(--brown);
    margin-bottom: 1%;
  }
  .star-rating {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;
  }
  .small-text {
    margin: 4px;
    font-size: 95%;
    color: var(--brown);
  }
  .btn-review {
    color: white;
    padding: 2%;
    text-transform: uppercase;
    border-radius: 50px;
    display: block;
    /* width: 30%; */
    cursor: pointer;

    border: none;
    background: #624068;
  }
  .divider-solid {
    width: 90%;
    border-top: 1px solid #212529;
  }
  .divider-solid-fill {
    width: 100%;
    border-top: 1px solid #212529;
  }
  .amazing-reviews {
    width: 85%;
    margin-bottom: 2%;
  }
  .filter-div {
    width: 85%;
    display: flex;
    flex-direction: row;
    font-family: "Quicksand-SemiBold";
    color: #212529;
  }
  .review-count {
    width: 50%;
    align-items: flex-start;
    text-align: start;
    color: #212529;
  }
  .review-filter {
    width: 50%;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: end;
    color: var(--brown);
  }
  .review-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 2%;
  }
  .review-container-details {
    display: flex;
    flex-direction: column;
    width: 90%;
  }
  .review-container-rating-details {
    display: flex;
    flex-direction: row;
  }
  .review-name {
    /* margin-left: 1%; */
    font-weight: bold;
    color: var(--brown);
  }
  .reviewer-image {
    display: flex;

    flex-direction: column;
  }
  .btn-review {
    /* width: 40%; */
    padding: 3%;
  }
  .submitbuttonreferral {
    width: 20%;
    color: white;
  }
  .input {
    width: 80%;
  }
}


@media all and (max-width: 389.98px) {
  .book-review {
    width: 80%;
    display: flex;
    flex-direction: column;
    padding: 2%;
  }

  .modal-title-container {
    flex-direction: column;
  }
  .modal-header {
    height: 70px;
  }
  .modal-close-btn {
    margin-left: 2%;
    background: #59514e;
    border: none;
    width: fit-content;
    color: white;
    padding: 1% 2%;
    border-radius: 50px;

    font-weight: bold;
  }
  @media all and (max-width: 836.98px) {
    .book-rating {
      font-size: 150%;

      font-weight: bold;
      color: var(--brown);
      margin-bottom: 1%;
    }
    .star-rating {
      flex-direction: column;
    }
    .btn-review {
      /* width: 40%; */
      padding: 3%;
    }
    .submitbuttonreferral {
      width: 20%;
      color: white;
    }
    .input {
      width: 80%;
    }
  }
  /* @media all and (max-width: 658.98px) {
   .btn-review {
      width: 60%;
    }
  } */
  @media all and (max-width: 389.98px) {
    .book-review {
      width: 80%;
      display: flex;
      flex-direction: column;
      padding: 2%;
    }
    /* .btn-review {
      width: 70%;
    } */
    .modal-title-container {
      flex-direction: column;
    }
    .modal-header {
      height: 70px;
    }
    .modal-des-container {
      width: 90%;
    }
  }
}
