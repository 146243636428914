.wrapper-for-banner-and-home-products{
    display: flex;
}
.banner-main-wrapper{
    width: 60%;
}
.banner-image img{
    width: 57.5%;
    /* height: 370px; */
}
.home-products-title{
    padding-left: 18px;
    font-size: 18px;
    font-weight: 550;
}
.popular-books-title{
    margin-left: 10px;
    font-size: 18px;
    font-weight: 550;

}
.new-books-container{
    padding-bottom: 100px;
}
.home-products-main-wrapper{
    padding-left: 50px;
}

@media (max-width: 1000px) {
    .wrapper-for-banner-and-home-products{
        display: block;
    }
    .banner-main-wrapper{
        width: 100%;
    }
    .banner-image img{
        width: 100%;
    }
    .home-products-title{
        text-align: center;
    }
  }
  @media (max-width: 520px) {
    /* .banner-image img {
        height: -webkit-fill-available;
      } */
      .home-products-main-wrapper{
        padding-left: 0px;
    }
  }

