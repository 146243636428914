
.ShowReview-container {
    display: flex;
    justify-content: center;
  }
  
  .ShowReview-box {
    margin: 2rem;
    border: 2px solid black;
    min-width: 100%;
  }
  
  .ShowReview-addproduct-btn {
    display: flex;
    justify-content: flex-end;
  }
  
  .ShowReview-addproduct-btn button {
    background-color: black;
    color: white;
    border: none;
    font-size: 19px;
    font-weight: 900;
    margin: 0.5rem;
    padding: 1rem 2rem;
    border-radius: 1rem;
  }
  
  .ShowReview-title {
    display: flex;
    justify-content: center;
    background-color: rgba(168, 167, 166, 0.699);
    font-weight: 900;
    font-family: monospace;
    padding: 1rem 0rem;
  }
  
  .ShowReview-divtable {
    overflow-x: auto;
  }
  
  .ShowReview-table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  .ShowReview-table td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 10px 15px;
  }
  
  .ShowReview-table tr:nth-child(even) {
    background-color: #dddddd;
  }
  
  .ShowReview-table img {
    /* width: 4rem;
      height: 5rem; */
    height: 100px;
    width: 100px;
  }
  
  .ShowReview-table button {
    font-weight: 900;
    padding: 0.5rem 1rem;
  }
  .ShowReview-summary {
    margin: 20px 0;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f7f7f7;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .ShowReview-summary p {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
  
  .ShowReview-summary span {
    color: #007bff;
  }
  
  .ShowReview-rating {
    vertical-align: middle;
    margin-left: 10px;
  }
  