
.showorder-container{
    display: flex;
    justify-content: center;
}

.showorder-box{
    margin: 2rem;
    border: 2px solid black;
    min-width: 80%;
}


.showorder-title{
    display: flex;
    justify-content: center;;
    background-color: rgba(168, 167, 166, 0.699);
    font-weight: 900;
    font-family: monospace;
    padding: 1rem 0rem;
}

/* .date-range-selectors{
    display: flex;
    justify-content: end;
    margin: 1rem;
    gap: 1rem;
} */

.date-range-selectors {
    display: flex;
    justify-content: end;
    margin-bottom: 1rem;
    gap: 1rem;
  }
  
  .date-range-selectors label {
    font-size: 16px;
    font-weight: bold;
  }
  
  .date-range-selectors input {
    padding: 0.5rem;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

.showorder-divtable{
    overflow-x: auto;
}

.showorder-table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;

  }
  
 .showorder-table td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 10px 40px;
  }

.showorder-table tr:nth-child(even) {
    background-color: #dddddd;
  }

.showorder-table button{
    font-weight: 900;
    padding: 0.5rem 1rem;   
    font-size: 18px;
    border: 0.2rem solid grey;
    background-color: white;
}

.showorder-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.showorder-cancel-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    z-index: 1000;
    width: 40%;
  }
  
  .showorder-cancel-textarea {
    width: 100%;
    height: 100px;
    resize: none;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    padding: 5px;
  }
  
  .showorder-cancel-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .showorder-cancel-buttons button {
    border: none;
    background-color: rgba(0, 0, 0, 0.1);
    color: #333;
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .showorder-cancel-close {
    cursor: pointer;
    font-size: 20px;
    color: #aaa;
  }
  

.ordermng-select{
    padding: 0.5rem 1rem;
    font-size: 18px;
    font-weight: 700;
    border: 0.2rem solid grey;
    background-color: white;
}

@media screen and (max-width:1000px) {
    .showorder-box{
        width:100%;
    }
}

@media screen and (max-width:850px) {
    .showorder-box{
        margin: 2rem 0rem;
    }

    .showorder-cancel-box {
        padding: 20px;
        z-index: 1000;
        width: 70%;
      }
}