.checkoutDetail-container{
    display: flex;
    justify-content: center;
    gap: 5%;
    margin-top: 2rem;
    padding-bottom: 150px;
}

.checkoutDetail-left-container{
    flex: 2;
    border: 1px solid rgb(192, 191, 191);
    min-height: 60vh;
    margin-left: 10%;
    max-width: 40vw;
    padding-left: 3%;
    margin-bottom: 1rem;
    padding-bottom: 2rem;
    background-color: white;
}

.checkoutDetail-address-oneaddress{
    width: 95%;
    min-height: 6rem;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
}

.checkoutDetail-address-oneaddress-error{
    width: 95%;
    min-height: 6rem;
    border: 1px solid #7c0505;
    border-radius: 2px;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
}

.checkoutDetail-address-personal text{
    padding: 0.5rem;
    font-weight: 550;
}

.checkoutDetail-address-info{
    margin-top: 0.2rem;
    margin-left: 0.5rem;
}

.checkoutDetail-address-info text{
    padding: 0.1rem;
}

.checkoutDetail-address-btn{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.2rem;
    margin-right: 0.2rem;
    gap: 1rem;
}

.checkoutDetail-address-btn button{
    border: none;
    background-color: #71447a;
    color: white;
    padding: 0.3rem 1rem ;
    font-weight: 700;
    border-radius: 50px;
}

.checkoutDetail-address-selectbtn{
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.checkoutDetail-address-selectbtn button{
    border: none;
    background-color: #71447a;
    color: white;
    padding: 0.3rem 1rem ;
    font-weight: 700;
    /* font-family: fantasy; */
    border-radius: 50px;
}

.checkoutDetail-address-oneaddress-error button{
    border: none;
    background-color: rgb(126, 17, 26);
    color: white;
    padding: 0.3rem 1rem ;
    font-weight: 700;
}

@media screen and (max-width: 800px) {
    .checkoutDetail-left-container{
        margin-left: 2% ;
        max-width: 50vw;
    }
}

@media screen and (max-width: 750px) {
    .checkoutDetail-container{
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .checkoutDetail-left-container{
        margin-bottom: 1rem;
        min-width: 80vw;
        margin-right: 2%;
    }
}

@media screen and (max-width: 450px) {
    .checkoutDetail-left-container{
        margin-bottom: 1rem;
        min-width: 95%;
        margin-right: 2%;
    }
}

.checkoutDetail-logincontainer{
    display: flex;
    flex-direction: column ;
    justify-content: center;
    align-items: center;
    gap:1.5rem;
}

.checkoutDetail-logincontainer_or{
    font-weight: 800;
    font-size: 20px;
}

.checkoutDetail-logincontainer button{
    padding: 0.5rem 5rem;
    border-radius: 50px;
    border: none;
    color: white;
    background-color: #71447a;
    font-size: 16px;
    font-weight: 600;
}



/* product listing css */
.checkoutDetail-box-item-list{
    display: flex;
    flex-direction: column;
    width: 95%;
}

.checkoutDetail-box-item{
    display: flex;
    border: 1px solid rgb(192, 191, 191);
    margin-top: 1.5%;
}

.checkoutDetail-box-item-img{
    min-width: 10rem;
    max-width: 10rem;
    height: 100%;
    cursor: pointer;
    margin: 1rem;
}

.checkoutDetail-box-item-img img{
    width: 100%;
    max-width: 120px;
    height: 120px;
    object-fit: fill;
}

.checkoutDetail-box-item-des{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 1% 4%;
}

.checkoutDetail-box-item-des-title{
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    height: 4rem;
    overflow: hidden;
}

.checkoutDetail-box-item-des-price{
    font-size: 17px;
    font-weight: 200;
    color: grey;
}

/* @media screen and (max-width:1100px) {
    .checkoutDetail-box-item-list{
        margin: 0% 10%;
    }
} */

/* @media screen and (max-width:700px) {
    .checkoutDetail-box-item-img img{
        object-fit: cover;
    }
}  */







/* amount detail css */
.checkoutDetail-right-container{
    flex:1;
    border: 1px solid rgb(192, 191, 191);
    margin-right: 10%;
    min-height:40vh ;
    max-width: 20vw;
    background-color: white;
}

.checkoutDetail-box-total-title{
    margin: 3%;
    font-weight: 800;
}

.checkoutDetail-box-total-detail{
    display: flex;
    justify-content: space-between;
    margin: 1% 5%;
    font-weight: 300;
    font-size: 18px;
}

.checkoutDetail-box-total-detail-amount{
    display: flex;
    justify-content: space-between;
    margin: 4% 5%;
    font-size: 22px;
    font-weight: 600;
    /* border-bottom: solid 2px #000; */
    border-top: solid 2px #000;
}

.checkoutDetail-box-total-detail-btn{
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.checkoutDetail-box-total-detail-btn button{
   background-color: #71447a;
   color: white;
    border: none;
    font-weight: 600;
    font-size: 17px;
    padding: 0.5rem 4rem;
    /* font-family: fantasy; */
    border-radius: 50px;
}




@media screen and (max-width:1500px) {
    .checkoutDetail-right-container{
        max-width: 30vw;
    }
}

@media screen and (max-width:1000px) {
    .checkoutDetail-right-container{
        max-width: 35vw;
        margin-right: 5%;
    }
}

@media screen and (max-width: 800px) {
    .checkoutDetail-right-container{
        max-width: 40vw;
        margin-right: 2%;
    }
}

@media screen and (max-width: 750px) {
    .checkoutDetail-right-container{
        min-width: 80vw;
        margin-left: 2%;
    }
}

@media screen and (max-width:450px) {
    .checkoutDetail-right-container{
        min-width: 95%;
        margin-left: 2%;
    }

    .checkoutDetail-box-total-detail-btn button{
         font-weight: 600;
         font-size: 16px;
         padding: 0.5rem 3rem;
         
    }
}

