.productdetail-container {
  display: flex;
  margin: 2rem;
  /* padding-bottom: 50px; */
}

.productdetail-image-container {
  display: flex;
  flex: 1;
}

.productdetail-image-container-list {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin: 1rem;
}

.productdetail-image-container-list img {
  /* width: 70%; */
  width: 80px;
  height: 80px;
  margin: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 5%;
  border-style: ridge;
}

.productdetail-image-container-view {
  width: 50%;
  height: 65vh;
  margin: 1rem;
}

.productdetail-image-container-view img {
  /* width: 100%;
    height: 100%; */
  width: 310px;
}

/* .productdetail-image-container-mb {
  display: none;
} */

.productdetail-desc-container {
  flex: 1;
  width: 50%;
}

.productdetail-desc-container-title {
  font-size: 22px;
  font-weight: 800;
  margin-left: 1rem;
  text-transform: uppercase;
  color: #71447a;
}

.productdetail-desc-container-price {
  display: flex;
  margin-left: 1rem;
}
.mr-price {
  color: rgba(0, 0, 0, 0.575) !important;
  font-size: 16px !important;
}

.productdetail-desc-container-discountprice {
  font-size: 25px;
  font-weight: 600;
  color: rgb(8, 8, 8);
  padding-left: 1rem;
}

.productdetail-desc-container-retailprice {
  font-size: 25px;
  font-weight: 600;
  color: #71447a;
}
.productdetail-desc-container-points {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.productdetail-desc-container-points.open {
  max-height: 750px; /* Adjust based on the expected height */
  transition: max-height 0.3s ease-in;
  padding-top: 13px;
}

.productdetail-desc-container-text {
  font-size: 17px;
  font-weight: 500;
  margin-left: 1rem;
}

.productdetail-desc-container-points {
  margin: 1rem 0rem;
  /* margin-left: 1rem; */
  margin-left: -15px;
  font-size: 14px;
}

.productdetail-desc-container-points li {
  padding-top: 0.5rem;
}

.productdetail-desc-container-button {
  margin-top: 3rem;
  gap: 20px;
  flex-wrap: wrap;
}

.productdetail-desc-container-buynow,
.productdetail-desc-container-addcart {
  margin-right: 2rem;
  font-size: 20px;
  font-weight: 900;
  padding: 0.8rem 3rem;
  border: none;
  border-radius: 2rem;
  background-color: #71447a;
  color: white;
}

.productdetail-desc-container-buynow:hover {
  background-color: #71447ade;
}

.productdetail-desc-container-addcart:hover {
  background-color: #71447ade;
}
.stock-available-text {
  color: green;
}
.stock-out-text {
  color: red;
}
.about-product {
  /* padding: 50px; */
  padding-left: 50px;
  padding-right: 50px;
  font-size: 14px;
}
.about-product h5 {
  color: #71447ade;
  font-size: 18px;
}
.products-box-error-detail {
  width: 100%;
  height: 1%;
  background-color: lightcoral;
}

.products-box-error-detail p {
  color: white;
  padding: 0.2rem 2rem;
  letter-spacing: 0.3rem;
}

/* @media screen and (max-width: 520px) { */

.productdetail-desc-container-buynow,
.productdetail-desc-container-addcart {
  font-size: 15px;
  font-weight: 600;
  margin: 0rem;
}

.productdetail-desc-container-button {
  display: flex;
  justify-content: space-around;
}

.productdetail-image-container-view {
  display: none;
}

.productdetail-image-container-list {
  display: none;
}

/* Mobile view */

.productdetail-image-container-mb {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.productdetail-image-container-view-mb {
  /* height: 40vh; */
  margin: 1rem 0rem;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.productdetail-image-container-view-mb img {
  /* margin-left: 5%; */
  width: 100%;
  /* height: 100%; */
  max-width: 600px;
  max-height: 600px;
  /* width: 450px; */
  /* height: 450px; */
  object-fit: cover;
}
.productdetail-image-container-view-mb video {
  /* margin-left: 5%; */
  width: 100%;
  /* height: 100%; */
  max-width: 600px;
  max-height: 600px;
  /* width: 450px; */
  /* height: 450px; */
  object-fit: cover;
}

.productdetail-image-container-list-mb::-webkit-scrollbar {
  display: none;
}

.productdetail-image-container-list-mb img , .productdetail-image-container-list-mb video {
  /* width: 24%; */
  width: 60px;
  height: 60px;
  cursor: pointer;
  margin-right: 1rem;
  border-radius: 5%;
  /* border-style: groove; */
}
/* } */
.productdetail-image-container-list-mb video{
  object-fit: cover;
  
}

.productdetail-image-container-list-mb {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 10vh;
  overflow-x: scroll;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding-left: 25px;
  padding-right: 25px;
}
.productDetials-wrapper {
  padding-left: 16px;
}

.productDetials-wrapper p {
  border-top: 1px solid purple;
  padding-top: 13px;
  cursor: pointer;

  padding-bottom: 13px;
  margin-bottom: -32px;
}

.whats-inside-para {
  border-bottom: 1px solid purple;
}
.button-disabled {
  background-color: rgba(70, 0, 70, 0.623);
  cursor:not-allowed;
}
.button-disabled:hover {
  background-color: rgba(84, 0, 84, 0.418);
  cursor: not-allowed;

}
@media (max-width: 1050px) and (min-width: 800px) {
  .productdetail-desc-container {
    margin-left: 0rem;
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media screen and (max-width: 1050px) {
  .productdetail-container {
    display: flex;
    flex-direction: column;
    margin-left: 0rem;
  }
  .productdetail-desc-container-button {
    display: flex;
    justify-content: center;
  }
  .about-product {
    padding: 20px;
  }
  .productdetail-image-container {
    justify-content: center;
    align-items: center;
  }
  .productdetail-desc-container-title {
    font-size: 18px;
    margin-left: 0rem;
    text-align: center;
  }
  .productdetail-image-container-mb,
  .productdetail-container,
  .productdetail-desc-container {
    width: 100%;
  }

  .productdetail-desc-container {
    padding-right: 20px;
    padding-left: 20px;

    padding-top: 25px;
  }
  .productdetail-desc-container-text {
    margin-left: 0rem;
  }
  .productDetials-wrapper {
    padding-left: 0px;
  }
  .productdetail-desc-container-price {
    margin-left: 0rem;
  }
}

@media screen and (max-width: 800px) {
  .productdetail-container {
    margin: 0rem;
  }
  .productdetail-image-container-list-mb img , .productdetail-image-container-list-mb video{
    /* width: 24%; */
    width: 50px;
    height: 50px;
  }
}
@media screen and (max-width: 640px) {
  .productdetail-image-container {
    justify-content: start;
  }
  .productdetail-desc-container-text {
    font-size: 14px;
  }
  .productdetail-image-container-list-mb img , .productdetail-image-container-list-mb video{
    /* width: 24%; */
    width: 50px;
    height: 50px;
  }
  .productdetail-desc-container {
    padding-right: 15px;
    padding-left: 15px;

  }
}
