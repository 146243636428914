.box-condainer{
    margin-top: 5rem;
}

.box-allbox{
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 20vh;
    
}

.box-one{
    margin: 1rem;
    height: 100%;
    width: 20%;
    background-color: aliceblue;
    box-shadow: 7px 7px 5px 0px #5e2068;
-webkit-box-shadow: 7px 7px 5px 0px #5e2068;
-moz-box-shadow: 7px 7px 5px 0px #5e2068;
}

.box-one-details{
    display: flex;
    flex-direction: column;
}

.box-one-details_title{
    font-size: 28px;
    font-weight: 900;
    font-family: monospace;
    padding-left: 2rem;
    padding-top: 2rem;
    color: grey;
}

.box-one-details_data{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    font-size: 40px;
    font-weight: 600;
}