.addshippinaddress-container{
    display: flex;
    justify-content: center;
    gap: 5%;
    margin-top: 2rem;
    padding-bottom: 150px;
}

.shipping-error-message{
    color: red;
    font-size: small;
}

.addshippinaddress-left-container{
    flex: 2;
    border: 1px solid rgb(192, 191, 191);
    min-height: 60vh;
    margin-left: 10%;
    max-width: 40vw;
    padding-left: 3%;
    padding-top: 2%;
    background-color: white;
}

.addshippinaddress-right-container{
    flex:1;
    border: 1px solid rgb(192, 191, 191);
    margin-right: 10%;
    min-height:40vh ;
    max-width: 20vw;
    background-color: white;
}

.addshippinaddress-box-total-title{
    margin: 3%;
    font-weight: 800;
}

.addshippinaddress-box-total-detail{
    display: flex;
    justify-content: space-between;
    margin: 1% 5%;
    font-weight: 300;
    font-size: 18px;
}

.addshippinaddress-box-total-detail-amount{
    display: flex;
    justify-content: space-between;
    margin: 4% 5%;
    font-size: 22px;
    font-weight: 600;
    border-bottom: dashed 2px #000;
    border-top: dashed 2px #000;
}

.addshippinaddress-box-total-detail-btn{
    display: flex;
    justify-content: center;
    height: 5vh;
    margin-bottom: 1rem;
}

.addshippinaddress-box-total-detail-btn button{
   background-color: #71447a;
   color: white;
    width: 80%;
    height: 100%;
    border: none;
    font-weight: 600;
    font-size: 18px;
    font-family: cursive;
    border-radius: 20px;
}


@media screen and (max-width:1500px) {
    .addshippinaddress-right-container{
        max-width: 30vw;
    }
}

@media screen and (max-width:1000px) {
    .addshippinaddress-right-container{
        max-width: 35vw;
        margin-right: 5%;
    }

    .addshippinaddress-left-container{
        margin-left: 5% ;
        max-width: 45vw;
    }
}

@media screen and (max-width: 800px) {
    .addshippinaddress-right-container{
        max-width: 40vw;
        margin-right: 2%;
    }

    .addshippinaddress-left-container{
        margin-left: 2% ;
        max-width: 50vw;
    }
}

@media screen and (max-width:750px){
    .addshippinaddress-container{
        display: flex;
        align-items: center;
        flex-direction: column;
       
    }

    .addshippinaddress-right-container{
        min-width: 80vw;
        margin-left: 2%;
    }

    .addshippinaddress-left-container{
        margin-bottom: 1rem;
        min-width: 80vw;
        margin-right: 2%;
    }
}

@media screen and (max-width:450px) {
    .addshippinaddress-right-container{
        min-width: 95%;
        margin-left: 2%;
    }

    .addshippinaddress-left-container{
        margin-bottom: 1rem;
        min-width: 95%;
        margin-right: 2%;
    }
}