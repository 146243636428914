.addressGuest-left-container-title{
    font-size: 22px;
    /* font-family: fantasy; */
}

.addressGuest-left-container-field{
 display: flex;
 flex-direction: column;
 margin-bottom: 1rem;
}

.addressGuest-left-container-field text{
    color: rgb(145, 142, 142);
}

.addressGuest-left-container-field-text{
    width: 80%;
    border: 0;
    outline: 0;
    border-bottom: 1px solid #bebdbd;
}

.addressGuest-left-container-addresstype-title{
    font-weight: 550;
}

.addressGuest-left-container-buttons{
    display: flex;
    align-items: row;
}

.addressGuest-left-container-radiobutton{
    margin: 1rem 1.5rem;
}

.addressGuest-left-container-savebutton{
   display: flex;
   align-items: center;
   justify-content: center;
}

.addressGuest-left-container-savebutton button{
    margin-bottom: 2rem;
    font-weight: 600;
    font-size: 16px;
    color: white;
    background-color: #71447a;
    border: none;
    padding: 0.5rem 4rem;
    border-radius: 50px;
}

.addressGuest-right-container{
    flex:1;
    border: 1px solid rgb(192, 191, 191);
    margin-right: 10%;
    min-height:40vh ;
    max-width: 20vw;
}

.addressGuest-box-total-title{
    margin: 3%;
    font-weight: 800;
}

.addressGuest-box-total-detail{
    display: flex;
    justify-content: space-between;
    margin: 1% 5%;
    font-weight: 300;
    font-size: 18px;
}

.addressGuest-box-total-detail-amount{
    display: flex;
    justify-content: space-between;
    margin: 4% 5%;
    font-size: 22px;
    font-weight: 600;
    border-bottom: dashed 2px #000;
    border-top: dashed 2px #000;
}

.addressGuest-box-total-detail-btn{
    display: flex;
    justify-content: center;
    height: 5vh;
    margin-bottom: 1rem;
}

.addressGuest-box-total-detail-btn button{
   background-color: #71447a;
   color: white;
    width: 80%;
    height: 100%;
    border: none;
    font-weight: 600;
    font-size: 18px;
}

.addressGuest-error-message{
    color: red;
    font-size: small;
}

@media screen and (max-width:1500px) {
    .addressGuest-right-container{
        max-width: 30vw;
    }
}

@media screen and (max-width:1000px) {
    .addressGuest-right-container{
        max-width: 35vw;
        margin-right: 5%;
    }

    .addressGuest-left-container{
        margin-left: 5% ;
        max-width: 45vw;
    }
}

@media screen and (max-width: 800px) {
    .addressGuest-right-container{
        max-width: 40vw;
        margin-right: 2%;
    }

    .addressGuest-left-container{
        margin-left: 2% ;
        max-width: 50vw;
    }
}

@media screen and (max-width:750px){
    .addressGuest-container{
        display: flex;
        align-items: center;
        flex-direction: column;
       
    }

    .addressGuest-right-container{
        min-width: 80vw;
        margin-left: 2%;
    }

    .addressGuest-left-container{
        margin-bottom: 1rem;
        min-width: 80vw;
        margin-right: 2%;
    }
}

@media screen and (max-width:450px) {
    .addressGuest-right-container{
        min-width: 95%;
        margin-left: 2%;
    }

    .addressGuest-left-container{
        margin-bottom: 1rem;
        min-width: 95%;
        margin-right: 2%;
    }
}