.orders-container{
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.orders-box{
    width: 50rem;
    min-height: 30rem;
    background-color:#ffffff;
    margin-left: 3%;
}

.orders-title{
    display: flex;
    justify-content: center;
    font-weight: 800;
    font-family: monospace;
    padding: 1rem 0rem;
    border-bottom: 2px solid #f0f0f0;
}

.orders-all_order{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.orders-one_order{
    width: 100%;
    min-height: 7rem;
    border: 4px double #cac6c6;
    margin-top: 1rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.orders-one_order img{
    width: 15%;
    height: 100%;

}

.orders-product_name{
    /* font-family: fantasy; */
    font-size: 17px;
    width: 9rem;
}

.orders-product_list{
    display: flex;
    flex-direction: column;
}

.orders-product_price{

}

.orders-product_price{

}

.orders-product_update{
    display: flex;
    flex-direction: column;
}

.orders-product_status{
    font-weight: 700;
    /* font-family: fantasy; */
}

@media screen and (max-width:1150px) {
    .orders-box{
        width: 40rem;
    }
}

@media screen and (max-width:850px) {
    .orders-container{
        display: flex;
        justify-content: center;
        margin-top: 1rem;
    }

    .orders-box{
        min-width: 100%;
        margin-bottom: 1rem;
        margin-left: 0rem;
    }
}

@media screen and (max-width:500px) {

    .orders-box{
        min-width: 100%;
        margin-bottom: 1rem;
        margin-left: 0rem;
    }

    .orders-one_order{
        width: 100%;
        min-height: 7rem;
        border: 4px double #cac6c6;
        margin: 0.5rem 0rem;
    }
    .orders-product_price{
        display: none;
    }
}