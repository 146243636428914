.admin_login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #624068;
  background: -moz-linear-gradient(45deg, #624068 0%, #624068 100%);
  background: -webkit-gradient(
    left bottom,
    right top,
    color-stop(0%, #624068),
    color-stop(100%, #624068)
  );
  background: -webkit-linear-gradient(45deg, #624068 0%, #624068 100%);
  background: -o-linear-gradient(45deg, #624068 0%, #624068 100%);
  background: -ms-linear-gradient(45deg, #624068 0%, #624068 100%);
  background: linear-gradient(45deg, #624068 0%, #624068 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7b5e80', endColorstr='#624068', GradientType=1 );
}

.admin_login-box {
  box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.75);
  min-width: 25%;
  min-height: 50%;
  background-color: white;
  margin: 0rem 1rem;
}

.admin_login-title {
  display: flex;
  justify-content: center;
  margin: 1rem 0rem;
  font-weight: 700;
  font-family: monospace;
}

.admin_login-field {
  margin: 3rem 3rem;
}

.admin_login-field input {
  font-size: 18px;
}

.admin_login-button {
  display: flex;
  justify-content: center;
}

.admin_login-button button {
  padding: 0.5rem 3rem;
  font-weight: 800;
  border-color: antiquewhite;
  margin-bottom: 2rem;
}

@media screen and (max-width: 550px) {
  .admin_login-box {
    min-width: 18%;
  }

  .admin_login-field {
    margin: 3rem 1rem;
  }
}
