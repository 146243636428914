/* Otp.css */

.otp-container {
    margin-top: 20px;
  }
  
  .otp-label {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .otp-inputs {
    display: flex;
  }
  .otp-inputs input {
    padding: 0px !important;
  }
  .otp-input {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    font-size: 18px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* Style for last input field to prevent margin-right */
  .otp-input:last-child {
    margin-right: 0;
  }
  