.page-container {
  display: flex;
  justify-content: center;
  background-color: #f0f0f0;
}



@media screen and (max-width: 850px) {
  .page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

}


