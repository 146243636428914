.homeproducts-box-container {
  display: flex;
  /* flex-wrap: wrap; */
  flex-flow: wrap;
  /* justify-content: center; */
}

.homeproducts-box {
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0 0 1px rgb(10 10 10 / 2%);
  width: 100%;
  max-width: 130px;

  min-height: 21vh;
  margin: 1% 2%;
  border-radius: 0.25rem;
  background-color: white;
}

.homeproducts-box:hover {
  box-shadow: 1.5px 1.5px 2.5px 3px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 1.5px 1.5px 2.5px 3px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 1.5px 1.5px 2.5px 3px rgba(0, 0, 0, 0.4);
}

.homeproducts-img-container {
  width: 100%;
  overflow: hidden;
  border-radius: 2% 2% 0 0;
  height: 9rem;
}

.homeproducts-img {
  width: 100%;
  height: 9rem;
  border-radius: 2% 2% 0 0;
  transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  cursor: pointer;
}

.homeproducts-img:hover {
  transform: scale(1.2);
  overflow: hidden;
  border-radius: 2%;
}

.homeproducts-text-box {
  width: 100%;
  float: left;
  padding: 12px;
}

.homeproducts-text-box-price {
  display: flex;
}

.homeproducts-text-box-title p,
.homeproducts-text-box-discountprice {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0.2em auto;
  width: 100%;
  text-align: center;
}

.homeproducts-text-box-price {
  display: flex;
}

.homeproducts-text-box-retialprice {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1em;
  text-transform: uppesrcase;
  margin: 0.2em auto;
  width: 100%;
  text-align: center;
  color: rgb(17, 16, 16);
}

.homeproducts-text-box-title {
  height: 2.5rem;
  /* overflow: hidden; */
  /* width: 200px; Set the width of the container */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis;
}

.homeproducts-text-box-title p {
  /* font-size: 1rem; */
  font-size: 8px;
  text-align: start;
}

.homeproducts-text-box-discountprice {
  color: rgb(8, 8, 8);
}

.homeproducts-text-box-buybtn {
  display: grid;
  width: 100%;
  margin: 2%;
  padding: 1% 0%;
  border: none;
  font-weight: 700;
  background-color: #5e2068;
  color: white;
  border-radius: 20px;
}

.homeproducts-text-box-gotocrtbtn,
.homeproducts-text-box-crtbtn {
  display: grid;
  width: 100%;
  margin: 2%;
  padding: 1% 0%;
  border: none;
  font-weight: 700;
  background-color: rgb(255, 255, 255);
  color: #5e2068;
  border: 1px solid #5e2068;
  border-radius: 20px;
}

.homeproducts-text-box-buybtn:hover {
  background-color: #5e2068e7;
}

.homeproducts-text-box-crtbtn:hover {
  background-color: #5e2068e7;
  color: white;
}

.homeproducts-text-box-gotocrtbtn:hover {
  background-color: #5e2068e7;
  color: white;
}
.product-name-home-products {
  font-weight: 600 !important;
}

@media screen and (max-width: 900px) {
  .homeproducts-box {
    width: 44%;
  }
}

@media (max-width: 520px) {
  .homeproducts-box-container {
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  .homeproducts-img {
    width: 100%;
    padding: 0.5rem;
  }
  .homeproducts-box {
    width: 46%;
    margin: 1% 2%;
    border-radius: 0.25rem;
  }
}