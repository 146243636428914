.contact-us-privacy-policy-and-other-main-wrapper {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 20px;
  min-height: 80vh;
}
.contact-us-privacy-policy-and-other-main-wrapper p{
    color: rgba(0, 0, 0, 0.768);
    font-size: 15px;

}
.contact-us-privacy-policy-and-other-main-wrapper h4 {
  text-align: center;
  padding-bottom: 20px;
  font-weight: 700;
}
.contact-us-privacy-policy-and-other-main-wrapper h4,
.contact-us-privacy-policy-and-other-main-wrapper h5 {
  color: #624068;
}
.copy-right-wrapper{
    background-color: #624068;
    text-align: center;
    color: white;
    cursor: pointer;
    padding-bottom: 20px;
    font-size: 14px;
}
.sub-titiles-for-contact-us-and-all{
    font-weight:700;
}
.social-media-link{
    text-decoration: underline;
    cursor: pointer;
color: black;
}
.bottom-title-contact-us-and-all{
    color: #624068;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 40px;
}

@media screen and (max-width:1200px) {
    .contact-us-privacy-policy-and-other-main-wrapper {
        padding-left: 50px;
        padding-right: 50px;
   
      }
}

@media screen and (max-width:800px) {
    .contact-us-privacy-policy-and-other-main-wrapper {
        padding-left: 20px;
        padding-right: 20px;
   
      }
      .bottom-title-contact-us-and-all{
        font-size: 10px;
        padding-bottom: 20px;
    }
    .contact-us-privacy-policy-and-other-main-wrapper p{
        font-size: 14px;
    
    }
}