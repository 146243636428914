.otp-field {
    display: flex;
    
}

.otp-field input {
    width: 5rem;
    font-size: 32px;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    margin: 2px;
    border: 2px solid #55525c;
    background: #21232d;
    font-weight: bold;
    color: #fff;
    outline: none;
    transition: all 0.1s;
}

.otp-field input:focus {
    border: 2px solid #a527ff;
    box-shadow: 0 0 2px 2px #a527ff6a;
}

.disabled {
    opacity: 0.5;
cursor: not-allowed;
}
