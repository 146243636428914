.address-container{
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.address-box{
    width: 50rem;
    min-height: 30rem;
    background-color:#ffffff;
    margin-left: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.address-title{
    display: flex;
    justify-content: center;
    font-weight: 800;
    /* font-family: monospace; */
    padding: 1rem 0rem;
  
}

.address-addnewaddress{
    display: flex;
    align-items: center;
    padding: 0rem 1rem;
    width: 90%;
    min-height: 3rem;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    font-size: 14px;
    color: #2874f0;
    font-weight:70  0;
    cursor: pointer;
    gap: 1rem;
}

.address-oneaddress{
    width: 90%;
    min-height: 6rem;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    margin-top: 2rem;
    padding-bottom: 10px;
}

.address-dsc{
    width: 70%;
}

.address-personal{
    margin-top: 1rem;
}

.address-personal text{
    padding: 1rem;
    font-weight: 550;
}

.address-info{
    margin-top: 0.5rem;
    margin-left: 0.5rem;
}

.address-info text{
    padding: 0.5rem;
}

.addressforms-box{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 50%;
    margin-left: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;

}

.address-btn{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.2rem;
    margin-right: 0.2rem;
    gap: 1rem;
}

.address-btn button{
    border: none;
    border-radius: 50px;
    background-color: #5e2068;
    color: white;
    padding: 0.3rem 1rem ;
}

@media screen and (max-width:1200px) {
    .address-box{
        width: 40rem;
        margin-left: 0.3rem;
    }
}   


@media screen and (max-width:850px) {
    .address-container{
        margin-top: 1rem;
    }
    .address-box{
        width: 100%;
        margin-left: 0rem;
    }
    .address-addnewaddress{
        width: 95%;
    }
    .address-dsc{
        width: 100%;
    }

    .address-oneaddress{
        width: 95%;
    }
    .addressforms-box{
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 1rem 0rem;
    }

}

