.products-container {
  width: 100%;
  display: flow-root;
  padding: 2%;
}

.products-box-error {
  width: 95%;
  height: 1%;
  background-color: lightcoral;
  margin: 0% 2.5%;
}

.products-box-error p {
  color: white;
  padding: 0.2rem 2rem;
  letter-spacing: 0.3rem;
}

.products-banner {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;


}

.products-banner-text {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  font-weight: 800;
  font-family: "Courier New", Courier, monospace;
  font-style: italic;
  color: white;
  text-transform: uppercase;
}

.products-banner-img {
  /* height: 100%;
  width: 100%; */
  width: 80%;
  max-width: 100%;
  /* height: 100%; */
  object-fit: fill;
  cursor: pointer;
}

.products-box-container {
  display: flex;
  /* justify-content: center; */
  flex-wrap: wrap;
  flex-flow: wrap;
  padding-bottom: 100px;
}

.products-box {
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0 0 1px rgb(10 10 10 / 2%);
  width: 18%;
  min-height: 43vh;
  margin: 1% 2%;
  border-radius: 0.25rem;
}

.products-box:hover {
  box-shadow: 1.5px 1.5px 2.5px 3px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 1.5px 1.5px 2.5px 3px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 1.5px 1.5px 2.5px 3px rgba(0, 0, 0, 0.4);
}

.products-img-container {
  width: 100%;
  overflow: hidden;
  border-radius: 2% 2% 0 0;
}

.products-img {
  width: 100%;
  padding: 1rem;
  height: 15em;
  /* background-size: cover; 
    background-position: center center; 
    background-repeat: no-repeat; */
  cursor: pointer;
  border-radius: 2% 2% 0 0;
  transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
}

.products-img:hover {
  transform: scale(1.2);
  overflow: hidden;
  border-radius: 2%;
}

.products-text-box {
  width: 100%;
  float: left;
  padding: 1em;
}

.products-text-box-price {
  display: flex;
  cursor: pointer;
}

.products-text-box-title p,
.products-text-box-discountprice {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1em;
  text-transform: uppercase;
  margin: 0.2em auto;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.products-text-box-retialprice {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1em;
  text-transform: uppercase;
  margin: 0.2em auto;
  width: 100%;
  text-align: center;
  color: rgb(5, 5, 5);
}

.products-text-box-title {
  /* height: 5rem; */
  overflow: hidden;
}

.products-text-box-title p {
  font-size: 12px;
}

.products-text-box-discountprice {
  color: rgb(8, 8, 8);
}

.products-text-box-buybtn {
  display: grid;
  width: 100%;
  margin: 2%;
  padding: 1% 0%;
  border: none;
  font-weight: 700;
  background-color: #71447a;
  color: white;
  border-radius: 20px;
}

.products-text-box-crtbtn,
.products-text-box-gotocrtbtn {
  display: grid;
  width: 100%;
  margin: 2%;
  padding: 1% 0%;
  border: none;
  font-weight: 700;
  background-color: white;
  color: #71447a;
  border: 1px solid #71447a;
  border-radius: 20px;
}

.products-text-box-buybtn:hover {
  background-color: #71447a;
}

.products-text-box-crtbtn:hover {
  background-color: #71447a;
  color: white;
}

.products-text-box-gotocrtbtn:hover {
  background-color: #71447a;
  color: white;
}
.author-name {
  font-size: 10px !important;
  color: rgb(94, 94, 94);
}
.books-soon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 550px;
  flex-direction: column;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  color: #71447a;
}
.books-soon-wrapper img {
  max-width: 200px;
  width: 100%;
}
.out-of-stock-product-listing{
    text-align: center;
    color: red;
}

@media screen and (max-width: 1200px) {
  .products-box {
    width: 29%;
  }
  .books-soon-wrapper {
    min-height: 450px;
  }
}

@media screen and (max-width: 900px) {
  .products-box {
    width: 44%;
  }
  .books-soon-wrapper img {
    max-width: 160px;
  }
}

@media screen and (max-width: 500px) {
  .products-banner-text {
    font-size: 45px;
  }
  .products-box {
    width: 46%;
    min-height: 40vh;
    margin: 1% 2%;
    border-radius: 0.25rem;
  }

  .products-img {
    width: 100%;
    padding: 0.5rem;
    height: 10em;
  }
  /* .products-banner {
    height: 25vh;
  } */
  .books-soon-wrapper img {
    max-width: 130px;
  }
}
