.CatagerisItem-MainContainer {
  display: flex;
  margin: 3px;
  min-width: 15rem;
  max-width: 15rem;
  height: 20rem;
  cursor: pointer;
  position: relative;
  border-radius: 6px;
}
.CatagerisItem-Overlay {
  position: absolute; /* Position the overlay on top */
  top: 0; /* Align to the top of the container */
  left: 0; /* Align to the left of the container */
  width: 100%; /* Cover the entire width */
  height: 100%; /* Cover the entire height */
  background-color: rgba(
    0,
    0,
    0,
    0.549
  ); /* Set a semi-transparent black background with low opacity */
  opacity: 0.2; /* Set the overall opacity (can be used instead of rgba) */
  /* Optional styles: */
  z-index: 1; /* Ensure the overlay is on top of other elements */
  border-radius: 6px;
}

.CatagerisItem-Image {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.CatagerisItem-Info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 210px;
  justify-content: center;
  padding-left: 35px;
  border-radius: 6px;
}

.CatagerisItem-Title {
  color: #624068;
  font-size: 15px;
  font-weight: 800;
  text-transform: uppercase;
  padding-top: 15px;
  max-width: 100px;
}

.CatagerisItem-Button {
  border: none;
  /* padding: 8px 22px; */
  /* background-color: white; */
  color: rgb(0, 0, 0);
  cursor: pointer;
  /* border-radius: 20px; */
  letter-spacing: inherit;
  /* border-color: rgba(32, 32, 32, .25); */
  font-size: 13px;
  font-weight: 500;
}
.arrow-right-catagories {
  width: 55px;
  padding-left: 10px;
}
@media screen and (max-width: 550px) {
  .CatagerisItem-MainContainer {
    min-width: 12rem;
    max-width: 12rem;
    height: 16rem;
  }
  .CatagerisItem-Info {
    gap: 150px;
    /* align-items: center; */

    padding-left: 30px;
  }
}
