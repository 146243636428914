.cart-container {
  padding-bottom: 150px;
  padding-top: 50px;
}

.success-alert {
  margin: 1rem;
  width: 85%;
}

.cart-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 2% 10%;
}

.cart-title h1 {
  color: rgb(71, 70, 70);
  font-weight: 700;
  font-size: 4rem;
}

.cart-box {
  display: flex;
  gap: 1%;
}

.cart-box-item-list {
  display: flex;
  flex: 2;
  flex-direction: column;
  margin-left: 10%;
}

.cart-box-item {
  display: flex;
  border: 1px solid rgb(192, 191, 191);
  margin-top: 1.5%;
  height: fit-content;
  background-color: white;
  padding-bottom: 20px;
}

.cart-box-item-img {
  width: 100%;
  /* height: 9rem; */
  cursor: pointer;
  margin: 0.5rem;
}

.cart-box-item-img img {
  width: 100%;
  height: 120px;
  max-width: 120px;
  object-fit: fill;
}

.cart-box-item-des {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1% 4%;
}

.cart-box-item-des-title p {
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  height: 4rem;
  overflow: hidden;
}

.cart-box-item-des-price {
  font-size: 17px;
  font-weight: 200;
  color: grey;
}

.cart-box-item-des-qty {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9%;
  /* border: 0.5px dashed rgba(128, 0, 128, 0.664); */
  width: fit-content;
  height: fit-content;
  width: 100px;
  border-radius: 50px;
  border: 2px solid #71447a;
  background-color: rgb(245, 245, 245);
}

.cart-box-item-des-qty-ri {
  height: 100%;

  /* border: 0.5px dashed rgba(128, 0, 128, 0.664); */
  border: none;
  background-color: rgb(245, 245, 245);
  cursor: pointer;
}

.cart-box-item-des-qty-ai {
  /* border: 0.5px dashed rgba(128, 0, 128, 0.664); */
  border: none;
  height: 100%;
  background-color: rgb(245, 245, 245);
  cursor: pointer;
}

.cart-box-item-button {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
}

.cart-box-item-button button {
  margin: 2%;
  border: none;
  color: rgb(255, 255, 255);
  background-color: #71447a;
  padding: 0.1rem 1rem;
  border-radius: 50px;
  /* font-family: fantasy; */
}

.cart-box-total {
  flex: 1;
  border: 1px solid rgb(192, 191, 191);
  margin-right: 10%;
  min-height: 40vh;
  background-color: white;
}

.cart-box-total-title {
  margin: 3%;
  font-weight: 800;
}

.cart-box-total-detail {
  display: flex;
  justify-content: space-between;
  margin: 1% 5%;
  font-weight: 300;
  font-size: 18px;
}

.cart-box-total-detail-amount {
  display: flex;
  justify-content: space-between;
  margin: 4% 5%;
  font-size: 22px;
  font-weight: 600;
  /* border-bottom: 2px solid #000; */
  border-top: 2px solid #000;
}

.cart-box-total-detail-btn {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.cart-box-total-detail-btn button {
  background-color: #71447a;
  color: white;
  width: 80%;
  padding: 1rem 0rem;
  border: none;
  font-weight: 600;
  font-size: 18px;
  margin: 5px 0px;
  border-radius: 50px;
  /* font-family: fantasy; */
}

.product-quantity-count-increment-and-decrement {
  margin-bottom: -1px;
}
@media screen and (max-width: 1100px) {
  .cart-box {
    display: flex;
    gap: 1%;
    flex-direction: column;
  }
  .cart-box-item-list {
    margin: 0% 10%;
  }
  .cart-box-total {
    margin: 2% 10%;
    min-height: 45vh;
  }
}

@media screen and (max-width: 700px) {
  .cart-box {
    display: flex;
    gap: 0%;
  }
  .cart-box-item-list {
    margin: 0% 2%;
  }

  .cart-box-total {
    margin: 2% 2%;
    min-height: 40vh;
  }
  .cart-title {
    height: 8vh;
    margin: 2% 0%;
    width: 100%;
  }

  .cart-title h1 {
    color: rgb(71, 70, 70);
    font-weight: 600;
    font-size: 2.5rem;
    font-style: italic;
    padding: 1rem;
  }
  .cart-box-item-img {
    min-width: 10rem;
  }
}

@media screen and (max-width: 500px) {
  .cart-box-item-img {
    min-width: 8rem;
  }

  /* .cart-box-item-img img {
    object-fit: contain;
  } */


  .cart-box-item {
    display: block;
  
  }
  .cart-box-total-detail-btn button {
  
    padding: 10px;
   
    font-weight: 600;
    font-size: 18px;
  

  }
}
