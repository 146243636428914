/* Show delivery charge component */

.deliverychargeshow-container{
    display: flex;
    justify-content: center;
}

.deliverychargeshow-box{
    margin: 2rem;
    border: 2px solid black;
    min-width: 50%;
}

.deliverychargeshow-title{
    display: flex;
    justify-content: center;;
    background-color: rgba(168, 167, 166, 0.699);
    font-weight: 900;
    /* font-family: monospace; */
    padding: 1rem 0rem;
}

.deliverychargeshow-divtable{
    overflow-x: auto;
}

.deliverychargeshow-table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;

  }
  
 .deliverychargeshow-table td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 10px 40px;
  }

.deliverychargeshow-table tr:nth-child(even) {
    background-color: #dddddd;
  }

.deliverychargeshow-table button{
    font-weight: 900;
    padding: 0.5rem 1rem;
}

@media screen and (max-width:1000px) {
    .deliverychargeshow-box{
        width:100%;
    }
}

@media screen and (max-width:850px) {
    .deliverychargeshow-box{
        margin: 2rem 0rem;
    }
}


    /* add Delivery Charge component
 */

 .AddDeliveryCharge-container{
    display: flex;
    justify-content: center;
    }
    
    
    .AddDeliveryCharge-box{
        min-width: 50%;
       min-height: 30vh;
        border: 8px solid rgb(139, 137, 137);
        margin: 3rem 0rem;
        box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
    }
    
    .AddDeliveryCharge-box-title{
        border-bottom: 1px solid grey;
    }
    
    .AddDeliveryCharge-box-title h1{
        display: flex;
        justify-content: center;
        font-weight: 700;
        /* font-family: fantasy; */
    }

    .AddDeliveryCharge-box-dataContainer{
        display: flex;
        justify-content: space-around;
    }
    
    .AddDeliveryCharge-box-data{
        display: flex;
        flex-direction: column;
        margin: 2rem ;
    }
    
    .AddDeliveryCharge-box-data text{
        font-size: 20px;
        font-weight: 600;
        color: rgb(91, 94, 91);
    }
    
    .AddDeliveryCharge-box-data input{
        width: 60%;
        font-size: 20px;
    }
    
    
    .AddDeliveryCharge-box-button{
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
       
    }
    
    .AddDeliveryCharge-box-button button{
        width: 20vw;
        height: 5vh;
        font-size: 20px;
        border: none;
        font-weight: 600;
        background-color: #5e2068;
        color: white;
    }

    @media screen and (max-width:700px){
        .AddDeliveryCharge-box-dataContainer{
            display: flex;
            flex-direction: column;
        }
    }
    
    @media screen and (max-width:500px){
        .AddDeliveryCharge-box-button button{
            width: 40vw;
        }
    }


    .error-message{
        display: flex;
        margin: 1rem 3rem;
        color: red;
    }