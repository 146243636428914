.banner-container {
  margin: 1rem;
}

.banner-image {
  width: 100vw;
  height: 30%; /* Set a fixed height for the banner */
  overflow: hidden;
  position: relative;
}

.banner-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.carousel-inner {
  border: 3px solid #5e2068;
  border-radius: 26px;
  margin-top: 48px;
}

.banner-skeleton {
  width: 100%;
  height: 300px; 
  background-color: #f0f0f0;
  border-radius: 26px;
  overflow: hidden;
}

.banner-skeleton-image {
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
}

@media screen and (max-width: 1000px) {
  .banner-image {
    width: 100vw;
  }

  .banner-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }

  .carousel-inner {
    margin-top: 48px;
  }
}

@media screen and (max-width: 600px) {
  .carousel-inner {
    margin-top: 48px;
    border: 2px solid #5e2068;
    border-radius: 10px;
  }
}
